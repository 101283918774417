import { AUTH0_PATH } from '@/components/layers/Auth0Layer/constants'
import { CurrentUser } from '@/server/services/Auth0/Auth0.types'
import { CognitoUserGroup } from '@/server/services/CognitoUserHelper/CognitoUserHelper.types'
import { getRedirectUrl } from '../utils/url'
import { KasValidation } from './pages.kas'

export type PageHref = ValueOf<typeof PAGE>

export { AUTH0_PATH }

/** README: See `./src/pages/` for details */
export const PAGE = {
  DASHBOARD: '/' as '/',
  WAITING: '/waiting' as '/waiting',
  /** @deprecated No Login page */
  LOGIN: '/login' as '/login',
  TREND_ANALYSIS: '/trend-analysis' as '/trend-analysis',
  MAP_VIEW: '/events-geolocation' as '/events-geolocation',
  PROPAGANDA_DETECTOR: '/propaganda-detector' as '/propaganda-detector',
  SUSPICION_NEWS: '/disinfo-identification' as '/disinfo-identification',
  CHANNELS_GRAPH: '/channels-graph' as '/channels-graph',
  PROMPT_RUNNER: '/prompt-runner' as '/prompt-runner',
  REPORTS: '/reports' as '/reports',
  ELECTION_INTEGRITY: '/input' as '/input',
  ELECTION_INTEGRITY_REPORTS: '/repository' as '/repository',
}

export const PAGE_TITLE_MAP: Partial<Record<PageHref, string>> = {
  [PAGE.DASHBOARD]: 'Trend Analysis',
  [PAGE.TREND_ANALYSIS]: 'Trend Analysis',
  [PAGE.MAP_VIEW]: 'Events Geolocation',
  [PAGE.PROPAGANDA_DETECTOR]: 'Propaganda Detection',
  [PAGE.SUSPICION_NEWS]: 'Potential Disinfo Identification',
  [PAGE.CHANNELS_GRAPH]: 'Data Visualization',
  [PAGE.PROMPT_RUNNER]: 'Prompt Runner',
  [PAGE.REPORTS]: 'Reports',
  [PAGE.ELECTION_INTEGRITY]: 'Election Integrity Hub',
  [PAGE.ELECTION_INTEGRITY_REPORTS]: 'Election Integrity Hub Repository ',
}

// Pages do not need authorization
export const PUBLIC_PAGES: PageHref[] = [PAGE.LOGIN]

export const RETURN_URL_PARAM = 'returnUrl'

export const getPageTitle = (page: PageHref) => PAGE_TITLE_MAP[page]

const getUserPageGroup = (pagePath: PageHref) => {
  const page = pagePath.replace('/', '')
  return `${CognitoUserGroup.PageUrl}${page}` as CognitoUserGroup.PageUrl
}

// TODO: Fix once done with new auth flow
// string[] => CognitoUserGroup[]
export const isPageVisible = (user: Partial<CurrentUser>, userGroups: string[], pageUrl?: PageHref): boolean => {
  const hubPages: PageHref[] = [PAGE.ELECTION_INTEGRITY, PAGE.ELECTION_INTEGRITY_REPORTS]
  if (pageUrl && hubPages.includes(pageUrl)) {
    return KasValidation.isPageKasAvailable(user)
  }

  if (KasValidation.isKasUser(user)) {
    return false
  }

  return true

  // const isMantisUser = userGroups?.includes(CognitoUserGroup.MantisTeam)
  // if (isMantisUser) {
  //   return true
  // }
  // return pageUrl ? !!userGroups.includes(getUserPageGroup(pageUrl)) : false
}

/**
 * TODO: Fix
 * @param userGroups userGroups: CognitoUserGroup[]
 * @returns
 */
export const getPagesByUserPermissions = ({
  user,
  userGroups,
}: {
  user: Partial<CurrentUser>
  userGroups: string[]
}): Omit<Record<PageHref, boolean>, '/login'> => ({
  [PAGE.DASHBOARD]: isPageVisible(user, userGroups, PAGE.TREND_ANALYSIS),
  [PAGE.TREND_ANALYSIS]: isPageVisible(user, userGroups, PAGE.TREND_ANALYSIS),
  [PAGE.MAP_VIEW]: isPageVisible(user, userGroups, PAGE.MAP_VIEW),
  [PAGE.PROPAGANDA_DETECTOR]: isPageVisible(user, userGroups, PAGE.PROPAGANDA_DETECTOR),
  [PAGE.SUSPICION_NEWS]: isPageVisible(user, userGroups, PAGE.SUSPICION_NEWS),
  [PAGE.CHANNELS_GRAPH]: isPageVisible(user, userGroups, PAGE.CHANNELS_GRAPH),
  [PAGE.PROMPT_RUNNER]: isPageVisible(user, userGroups, PAGE.PROMPT_RUNNER),
  [PAGE.REPORTS]: isPageVisible(user, userGroups, PAGE.REPORTS),
  [PAGE.ELECTION_INTEGRITY]: isPageVisible(user, userGroups, PAGE.ELECTION_INTEGRITY),
  [PAGE.ELECTION_INTEGRITY_REPORTS]: isPageVisible(user, userGroups, PAGE.ELECTION_INTEGRITY_REPORTS),
  [PAGE.WAITING]: isPageVisible(user, userGroups) || !userGroups.length,
})

export const getFirstAllowedPage = (
  pagePermissions: ReturnType<typeof getPagesByUserPermissions>,
): Exclude<PageHref, '/login'> => {
  const allowedPages = (Object.keys(pagePermissions) as PageHref[]).filter((key) => !!(pagePermissions as any)[key])
  const firstValidPath = allowedPages[0] as Exclude<PageHref, '/login'>
  const redirectUrl = getRedirectUrl(firstValidPath) as Exclude<PageHref, '/login'>
  const isRedirectUrlAllowed = !!allowedPages.filter((page) => redirectUrl?.startsWith(page))[0]

  return isRedirectUrlAllowed ? redirectUrl : firstValidPath
}
